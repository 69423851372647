import React, { useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import {
    ReturnDocumentService,
    WebExtensionService,
    WebLoginService,
} from "src/services";
import { getModuleId, replaceUrlParam } from "src/services/Utility";

import {
    ActiveReturnDocument,
    Company,
    ExtMessageResponse,
    ExtMessageResponseStatus,
    FixMeLater,
    FolderNode,
    LoginData,
    Product,
    PushReturnDocument,
    QueryParams,
    ReturnDocument,
    ReturnDocumentId,
    ReturnNode,
    ReturnTemplate,
    State,
    TabProperties,
    ValidationResponse,
} from "src/types";

import Toolbar from "src/components/Toolbar/Toolbar";
import "./PdfViewer.scss";

import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import FloatingWindow from "src/components/FloatingWindow/FloatingWindow";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { getQueryParams } from "src/utils";
import Attachments from "../Attachments/Attachments";
import EFileEmailModal from "../EFileEmailModal/EFileEmailModal";
import EFileModal from "../EFileModal/EFileModal";
import OptinsExport from "../OptinsExport/OptinsExport";
import PdfRender from "./PdfRender/PdfRender";
import {
    fetchDocument,
    fetchOnlyDocument,
    handleResponse,
} from "./PdfViewer.util";
import { getIconData } from "./toolbarIconsData";
import { useSelector } from "react-redux";
import SupportingWorksheet from "../SupportingWorksheet/SupportingWorksheet";
import { Backdrop, CircularProgress } from "@mui/material";

export interface PdfViewerProps {
    documentKey: string;
    isActive: boolean;
    company: Company;
    product: Product;
    folderNode: FolderNode;
    returnNode: ReturnNode;
    isSplitedScreen: boolean;
    generateTab: (
        company: Company,
        product: Product,
        folderNode: FolderNode,
        returnNode: ReturnNode,
    ) => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
    documentKey,
    isActive,
    company,
    product,
    folderNode,
    returnNode,
    isSplitedScreen,
    generateTab,
}) => {
    const dispatch = useAppDispatch();
    const returnDocumentService = ReturnDocumentService.getInstance();
    const webExtensionService = WebExtensionService.getInstance();
    const webLoginService = WebLoginService.getInstance();

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const [activatePriorYearReturns, setActivatePriorYearReturns] = useState<
        ActiveReturnDocument[]
    >([]);
    const [returnDocument, setReturnDocument] = useState<ReturnDocument>();
    const [returnTemplate, setReturnTemplate] = useState<ReturnTemplate>();
    const [validationResponse, setValidationResponse] =
        useState<ValidationResponse | null>(null);
    const [returnDocumentPaymentRequest, setReturnDocumentPaymentRequest] =
        useState<FixMeLater>();
    const [returnTemplatePaymentRequest, setReturnTemplatePaymentRequest] =
        useState<ReturnTemplate>();
    const [
        validationResponsePaymentRequest,
        setValidationResponsePaymentRequest,
    ] = useState<ValidationResponse | null>(null);
    const [efileEmailModalOpen, setEfileEmailModalOpen] =
        useState<boolean>(false);
    const [efileModalOpen, setEfileModalOpen] = useState<boolean>(false);
    const [attachmentsModalOpen, setAttachmentsModalOpen] =
        useState<boolean>(false);
    const [optinsModalOpen, setOptinsModalOpen] = useState<boolean>(false);
    const [showPaymentRequest, setShowPaymentRequest] =
        useState<boolean>(false);
    const [showNotes, setShowNotes] = useState<boolean>(false);
    const [supportingWorkSheetOpen, setSupportingWorkSheetOpen] =
        useState<boolean>(false);
    const [supportingWorkSheetId, setSupportingWorkSheetId] =
        useState<number>(0);
    const [supprtingWorkSheetTitle, setSupportingWorkSheetTitle] =
        useState<string>("Supporting Worksheet");
    const [triggerCount, setTriggerCount] = useState<number>(0);

    const [pendingChanges, setPendingChanges] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs,
    )?.tabsProperties;

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedState,
    );
    const municipalQuarter: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter,
    );

    const returnNotes = useAppSelector(
        (state) => state?.[product?.productName]?.value.returnNotes,
    );

    const selectedState: State = useSelector(
        (state) => state?.[product?.productName]?.value?.state,
    );

    const dropDownState: boolean = useAppSelector(
        (state) => state?.States?.dropDownState,
    );

    const splitScreen = useAppSelector((state) => state?.Tabs?.splitScreen);

    const taxYear = product?.taxYear;
    useEffect(() => {
        if (tabsProperties[documentKey]) {
            const updatedTabProperties: TabProperties = {
                ...tabsProperties[documentKey],
                isActive: isActive,
            };
            dispatch(GlobalStateActions.setTab(updatedTabProperties));
        }

        if (isActive) {
            replaceUrlParam("year", product?.taxYear);
            replaceUrlParam("companyId", company?.id);
            replaceUrlParam(
                "folderNodeId",
                dropDownState ? selectedState?.id : folderNode.id,
            );
            replaceUrlParam("returnNodeId", returnNode?.id);

            if (product?.productName === "Municipal") {
                replaceUrlParam("selectedState", municipalState);
                replaceUrlParam("selectedQuarter", municipalQuarter);
            }

            if (!pendingChanges) {
                //was not a necessary condition for updating URL
                fetchOnlyDocument(
                    requestFetchReturnDocument,
                    setReturnDocument,
                );
                if (returnNode.id != 1) {
                    // Don't call validations for genInfo (ReturnId = 1)
                    fetchDocument(
                        requestFetchReturnDocument,
                        setReturnDocument,
                        setValidationResponse,
                    );
                }
            }
        }
    }, [isActive, tabsProperties[documentKey]?.isLocked]);

    // properties to manage toolbar behavior
    const isManualSave =
        returnTemplate?.properties?.find(
            (property) => property?.key === "SaveType",
        )?.value === "MANUAL" || false;
    const hasPaymentRequest =
        (returnTemplate?.filingAddresses?.filingAddressRows[0]
            ?.hasPaymentRequest &&
            folderNode.id === returnNode?.retalFolderId) ??
        false;
    const hasInstructionsPdf = returnNode?.returnInstructionPdf !== "" || false;
    const hasOptinsExport = returnNode?.filingType === "OPTINS" || false;
    const priorYearReturn = activatePriorYearReturns?.find(
        (priorYearReturn: ActiveReturnDocument) =>
            priorYearReturn?.returnId == returnNode?.id,
    );
    const hasPriorYearReturn = !!priorYearReturn;

    const products: Product[] = useAppSelector(
        (state) => state?.Products?.value?.products,
    );

    const requestFetchReturnDocument: QueryParams = getQueryParams({
        companyId: dropDownState ? folderNode?.id?.toString() : company.id,
        productId: product?.productId,
        taxYear: product?.taxYear,
        folderId: dropDownState ? selectedState?.id : folderNode.id,
        moduleId: getModuleId(
            product,
            company ? (dropDownState ? folderNode : company) : {},
            municipalState,
        ),
        returnId: returnNode?.id,
        retalFolderId: returnNode?.retalFolderId,
    });

    useEffect(() => {
        resetData();
        checkPriorYear();
    }, []);

    const resetData = () => {
        setShowPaymentRequest(false);
        setReturnDocument(undefined);
        setReturnTemplate(undefined);
        setReturnTemplatePaymentRequest(undefined);
        setReturnDocumentPaymentRequest(undefined);
        setValidationResponse(null);
        setValidationResponsePaymentRequest(null);
        setActivatePriorYearReturns([]);
    };

    const checkPriorYear = async () => {
        const previousTaxYear = String(Number(taxYear) - 1);

        const documentParams: QueryParams = getQueryParams({
            companyId: company?.id,
            productId: product?.productId,
            taxYear: previousTaxYear,
        });

        try {
            const activeReturns =
                await returnDocumentService.getActiveReturns(documentParams);
            setActivatePriorYearReturns(activeReturns);
        } catch (error: FixMeLater) {
            console.error("Error fetching return Document:", error);
        }
    };

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const pushDocument = async (
        updatedReturnDocument: ReturnDocument,
        showMessages: boolean = true,
    ) => {
        setIsLoading(true);
        updatedReturnDocument.returnVersion =
            updatedReturnDocument.returnVersion ?? 0;
        updatedReturnDocument.returnVersion++;

        // Push the updated return document to the service
        try {
            if (
                !returnDocument ||
                !(isManualSave && pendingChanges) ||
                showMessages
            ) {
                const response: PushReturnDocument =
                    await returnDocumentService.pushReturnDocument(
                        updatedReturnDocument,
                    );

                setIsLoading(false);
                // Update the local state based on the response
                const updatedDocument = handleResponse(
                    updatedReturnDocument,
                    response,
                );

                // To update the other open return document or schedule if split screen is enabled
                // set the return or schedule just got updated to true
                // so that the other return document will be re-rendered and set it to false again
                if (splitScreen) {
                    dispatch(GlobalStateActions.setToogleFetch());
                }

                setValidationResponse(response.validationResponse);
                setReturnDocument(updatedDocument);
                setPendingChanges(false);
                dispatch(GlobalStateActions.removePendingChange(documentKey));
                // Display success message
                if (showMessages) {
                    handleSnackbar("saved", "success");
                }
            }
        } catch (error: FixMeLater) {
            setIsLoading(false);
            // Handle and display error message
            if (error.message === "Conflict") {
                handleSnackbar(
                    `Not saved.\nThe return has been updated outside of this session. please try again Error: ${error.message}`,
                    "error",
                );
                fetchOnlyDocument(
                    requestFetchReturnDocument,
                    setReturnDocument,
                );
            } else {
                // For other errors
                handleSnackbar(
                    `There has been an error saving data. ${error}`,
                    "error",
                );
                fetchOnlyDocument(
                    requestFetchReturnDocument,
                    setReturnDocument,
                );
            }
        }
    };

    const onOpenPriorYearReturn = (priorYear: FixMeLater) => {
        const p: Product | undefined = products?.find(
            (item) =>
                item.taxYear === parseInt(priorYear.taxYearId) &&
                item.productId === priorYear?.productId,
        );
        if (p) {
            generateTab(company, p, folderNode, returnNode);
        }
    };
    const isSupportingWorksheet = (hyperlink: string): [boolean, number] => {
        const regex = /^SW\.(\d+)$/;
        const match = hyperlink.match(regex);
        if (match) {
            const worksheetId = parseInt(match[1]);
            return [true, worksheetId];
        }
        return [false, 0];
    };

    const onClickHyperlinkCallback = (hyperlink: string) => {
        const [isSW, worksheetId] = isSupportingWorksheet(hyperlink);
        if (isSW) {
            setSupportingWorkSheetId(worksheetId);
            setSupportingWorkSheetOpen(true);
            return;
        }
    };

    const onWebLoginClick = async (returnDocumentId: ReturnDocumentId) => {
        const message = `Web login for ${returnNode?.displayName}`;

        const loginData: LoginData =
            await webLoginService.getLoginDataByReturnId(returnDocumentId);

        handleSnackbar(message, "info");

        const loginResponseData: ExtMessageResponse =
            await webExtensionService.doLogin(loginData);

        if (loginResponseData.status === ExtMessageResponseStatus.ERROR) {
            handleSnackbar(message + " failed", "error");
            return;
        }

        if (loginResponseData.status === ExtMessageResponseStatus.SUCCESS) {
            handleSnackbar(message + " completed", "success");
            return;
        }

        if (loginResponseData.status === ExtMessageResponseStatus.WARNING) {
            handleSnackbar(message + " completed with warnings", "warning");
            return;
        }
    };

    const iconData = getIconData(
        hasPriorYearReturn,
        hasInstructionsPdf,
        isManualSave && pendingChanges,
        hasPaymentRequest,
        hasOptinsExport,
        validationResponse,
        returnNode,
        pushDocument,
        setEfileEmailModalOpen,
        setEfileModalOpen,
        returnDocument,
        priorYearReturn,
        product,
        dropDownState ? { id: selectedState?.id } : folderNode,
        company,
        handleSnackbar,
        setShowPaymentRequest,
        showPaymentRequest,
        () => setAttachmentsModalOpen(true),
        () => setOptinsModalOpen(true),
        municipalState,
        dispatch,
        returnNotes,
        onOpenPriorYearReturn,
        onWebLoginClick,
        dropDownState,
    );

    return (
        <div className="pdf-viewer-container" data-testid="pdf-viewer">
            <Backdrop
                sx={{ zIndex: 19, position: "absolute" }}
                open={isLoading}
            >
                <div className="loader-section">
                    <span className="loader-text">Updating...</span>
                    <CircularProgress sx={{ color: "white" }} />
                </div>
            </Backdrop>
            <PdfRender
                documentKey={documentKey}
                returnDocument={returnDocument}
                setReturnDocument={setReturnDocument}
                setPendingChanges={setPendingChanges}
                returnTemplate={returnTemplate}
                setReturnTemplate={setReturnTemplate}
                setValidationResponse={setValidationResponse}
                company={company}
                product={product}
                folderNode={folderNode}
                returnNode={returnNode}
                isActive={isActive}
                isSplitedScreen={isSplitedScreen}
                onClickHyperlinkCallback={onClickHyperlinkCallback}
                triggerCount={triggerCount}
            ></PdfRender>
            {hasPaymentRequest && (
                <FloatingWindow
                    visible={showPaymentRequest}
                    setVisible={setShowPaymentRequest}
                    title={returnTemplatePaymentRequest?.displayName}
                >
                    <PdfRender
                        documentKey={documentKey}
                        isPaymentRequest={true}
                        setPendingChanges={setPendingChanges}
                        returnDocument={returnDocumentPaymentRequest}
                        setReturnDocument={setReturnDocumentPaymentRequest}
                        returnTemplate={returnTemplatePaymentRequest}
                        setReturnTemplate={setReturnTemplatePaymentRequest}
                        setValidationResponse={
                            setValidationResponsePaymentRequest
                        }
                        company={company}
                        product={product}
                        folderNode={folderNode}
                        returnNode={returnNode}
                        isActive={isActive}
                        isSplitedScreen={false}
                    ></PdfRender>
                </FloatingWindow>
            )}

            <div className="pdf-toolbar-container">
                <Toolbar items={iconData} />
            </div>

            <FloatingWindow
                visible={efileEmailModalOpen}
                setVisible={setEfileEmailModalOpen}
                title="Email through TriTech"
            >
                <EFileEmailModal
                    returnDocument={returnDocument!!}
                    open={efileEmailModalOpen}
                    validationResponse={validationResponse}
                />
            </FloatingWindow>

            <FloatingWindow
                visible={efileModalOpen}
                setVisible={setEfileModalOpen}
                title="E-File through TriTech"
            >
                <EFileModal
                    folderNode={folderNode}
                    returnDocument={returnDocument!!}
                    open={efileModalOpen}
                    validationResponse={validationResponse}
                />
            </FloatingWindow>

            <FloatingWindow
                visible={optinsModalOpen}
                setVisible={setOptinsModalOpen}
                title="Electronic Filing Through NAIC OPTins"
            >
                <OptinsExport
                    payload={{
                        ...requestFetchReturnDocument,
                        taxYearId: requestFetchReturnDocument.taxYear,
                    }}
                    onClose={() => setOptinsModalOpen(false)}
                    open={optinsModalOpen}
                />
            </FloatingWindow>

            <FloatingWindow
                visible={attachmentsModalOpen}
                setVisible={setAttachmentsModalOpen}
                title="Attachments"
            >
                <Attachments
                    returnDocument={returnDocument!!}
                    setReturnDocument={setReturnDocument}
                />
            </FloatingWindow>

            <FloatingWindow
                visible={supportingWorkSheetOpen}
                setVisible={setSupportingWorkSheetOpen}
                title={supprtingWorkSheetTitle}
                onClose={() => {
                    setTriggerCount(triggerCount + 1);
                }}
            >
                <SupportingWorksheet
                    supportingWorkSheetId={supportingWorkSheetId}
                    companyId={Number(
                        dropDownState ? folderNode?.id?.toString() : company.id,
                    )}
                    productId={product.productId}
                    taxYearId={product.taxYear}
                    folderId={folderNode.id.toString()}
                    moduleId={getModuleId(
                        product,
                        company ? (dropDownState ? folderNode : company) : {},
                        municipalState,
                    )}
                    returnId={returnNode.id.toString()}
                    retalFolderId={returnNode.retalFolderId?.toString() ?? ""}
                    setTitle={setSupportingWorkSheetTitle}
                ></SupportingWorksheet>
            </FloatingWindow>

            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default PdfViewer;
