import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import { LicenseInfo } from "@mui/x-license-pro";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { ALLOCATOR_LOG_TYPES, FixMeLater } from "src/types";
import "./App.scss";
import { fetchData } from "./App.util";
import ApplicationFooter from "./components/ApplicationFooter/ApplicationFooter";
import ApplicationNavBar from "./components/ApplicationNavBar/ApplicationNavBar";
import LandingPage from "./pages/LandingPage/LandingPage";
import AccountAdmin from "./products/AccountAdmin/AccountAdmin";
import Agent from "./products/Agent/Agent";
import FormsPlus from "./products/FormsPlus/FormsPlus";
import Municipal from "./products/Municipal/Municipal";
import PremiumTax from "./products/PremiumTax/PremiumTax";
import { BaseAPIService } from "./services/BaseApiService";
import { getFirstPathSegment } from "./services/Utility";
import ErrorLog from "./products/Allocator/components/ErrorLog/ErrorLog";
import Tools from "./products/Allocator/components/Tools/Tools";
import Allocator from "./products/Allocator/Allocator";
import { EmptyState, Undraw } from "./uikit";
import { ValidPaths } from "./constants/ValidPaths";
import { pdfjs } from "react-pdf";

const App = () => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY!);

    if (!(window as any).TRITECH) {
        (window as any).TRITECH = {};
    }

    if (!(window as any).TRITECH.DEBUG) {
        (window as any).TRITECH.DEBUG = {};
    }

    // proxy that intercepts changes in DISPLAY_FIELD_IDS from console
    (window as any).TRITECH.DEBUG = new Proxy((window as any).TRITECH.DEBUG, {
        set: (target, property, value) => {
            if (property === "DISPLAY_FIELD_IDS") {
                target[property] = value;
                window.dispatchEvent(new Event("debugModeChange"));
                return true;
            }
            return false;
        },
    });

    // Disable logs in production and stage
    if (
        process.env.REACT_APP_ENVIRONMENT === "production" ||
        process.env.REACT_APP_ENVIRONMENT === "stage"
    ) {
        console.log = () => {};
        console.info = () => {};
        console.warn = () => {};
        console.error = () => {};
    }

    const {
        isAuthenticated,
        isLoading,
        error,
        loginWithRedirect,
        getAccessTokenSilently,
    } = useAuth0();

    const dispatch = useAppDispatch();

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
        if (!error && !isLoading && isAuthenticated) {
            const fetchDataAsync = async () => {
                // Unique BaseAPIService instance for the entire application; set token generator
                const baseApiService = BaseAPIService.getInstance();
                baseApiService.setTokenGenerator(getAccessTokenSilently);

                await fetchData(dispatch);
            };
            fetchDataAsync();
        }

        if (!error && !isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [error, isLoading, isAuthenticated]);

    const products = useAppSelector(
        (state: FixMeLater) => state?.Products?.value?.processedProducts,
    );
    const product = useAppSelector((state) => state?.Product?.value);
    const companies = useAppSelector((state) => state?.Companies?.value);
    const self = useAppSelector((state) => state?.Self?.value);

    const firstPathSegment = getFirstPathSegment();
    const isValidPath = ValidPaths.includes(firstPathSegment);

    if (
        !error &&
        (isLoading ||
            !products ||
            (firstPathSegment &&
                isValidPath &&
                firstPathSegment != "not-authorized" &&
                !product) ||
            !companies ||
            !self)
    ) {
        return (
            <div className="loader-container">
                <CircularProgress />
            </div>
        );
    }

    if (!error && !isLoading && isAuthenticated)
        return (
            <BrowserRouter>
                <div className="application-container">
                    <ApplicationNavBar />

                    <div className="product-container">
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route
                                path="/account-admin"
                                element={<AccountAdmin />}
                            />
                            <Route path="/agent" element={<Agent />} />
                            <Route path="/allocator-app">
                                <Route index element={<Allocator />} />
                                <Route
                                    path="warnings/:id"
                                    element={
                                        <ErrorLog
                                            type={ALLOCATOR_LOG_TYPES.WARNING}
                                        />
                                    }
                                />
                                <Route
                                    path="errors/:id"
                                    element={
                                        <ErrorLog
                                            type={ALLOCATOR_LOG_TYPES.ERROR}
                                        />
                                    }
                                />
                                <Route path="tools" element={<Tools />} />
                            </Route>
                            <Route path="/formsplus" element={<FormsPlus />} />
                            <Route path="/municipal" element={<Municipal />} />
                            <Route path="/premium" element={<PremiumTax />} />
                            <Route
                                path="/not-authorized"
                                element={
                                    <EmptyState
                                        graphic={
                                            <Undraw fileName="NoAccess.svg" />
                                        }
                                        title="Oops!"
                                        text="You are not authorized to access this page."
                                        actions={[
                                            {
                                                label: "Go to home",
                                                onClick: () => {
                                                    window.location.href = "/";
                                                },
                                            },
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path="*"
                                element={
                                    <EmptyState
                                        graphic={
                                            <Undraw fileName="NoData.svg" />
                                        }
                                        title="404"
                                        text="Page not found."
                                        actions={[
                                            {
                                                label: "Go to home",
                                                onClick: () => {
                                                    window.location.href = "/";
                                                },
                                            },
                                        ]}
                                    />
                                }
                            />
                        </Routes>
                    </div>

                    <div className="application-footer">
                        <ApplicationFooter />
                    </div>
                </div>
            </BrowserRouter>
        );
};

export default App;
